<template>
  <v-row class="ma-0">
   <!-- welcome -->
   <v-col cols="6">
      <ax-welcome />
    </v-col>
    <!-- end welcome -->

    <v-col class="container-video" cols="6" style="align-items: center;">
      <v-col cols="8" class="mx-auto">
        <v-card class="mx-auto text-left pa-6 roundedXL" :max-width="450" flat align="center" outlined justify="center">
          <v-card-text class="px-2">
            <v-col class="text-center pa-0 mt-2 mb-6">
              <img :src="require(`@/assets/appBrand--fc-full${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" width="211">
              <span class="mt-1 d-block body-1 fontBody--text font-weight-bold">Iniciar sesión</span>
            </v-col>
            <span class="text-body-2 text-fontBody mt-3">Email</span>
            <v-text-field v-model="$v.user.email.$model" @keyup.enter="signIn" :error="$v.user.email.$error" outlined hide-details required single-line dense />
            <span class="d-block mt-5 text-body-2 text-fontBody">Contraseña</span>
            <v-text-field v-model="$v.user.password.$model" @keyup.enter="signIn" :error="$v.user.password.$error" outlined hide-details required single-line class="pa-0" dense type="password">
              <template v-slot:append>
                <v-btn class="mt-n1 mr-4 font-weight-medium" :to="{name: 'AuthPasswordReset'}" text color="primary" small :ripple="false">¿La olvidaste?</v-btn>
              </template>
            </v-text-field>
            <v-col v-if="error" class="pa-0 mt-3 text-center">
              <v-alert border="left" colored-border color="white" dense class="body-2 red--text pa-0">{{error}}</v-alert>
            </v-col>
            <v-btn class="mt-8 mb-2" @click="signIn" :loading="signInLoader" color="primary" block>Iniciar sesión</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import AxWelcome from '@/components/commons/AxWelcome'

export default {
  components: {
    AxWelcome
  },
  data: () => ({
    user: {
      email: '',
      password: ''
    },
    error: '',
    signInLoader: false
  }),
  created () {
    if (this.$store.state.auth.user.authenticated) {
      this.$router.push({
        name: 'AccountDashboard'
      })
    }
  },
  methods: {
    signInGoogle () {
      this.$gAuth.getAuthCode()
      .then(code => {
        const payload = {
          provider: 'google-oauth2',
          code: code
        }
        this.$store.dispatch('auth/GET_AUTH', {
          resource: '/auth/google/',
          payload: payload
        })
      })
      .then(response => {
        // after ajax
      })
    },
    signIn () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      const payload = {
        username: this.user.email,
        password: this.user.password
      }
      this.signInLoader = true
      this.$store.dispatch('auth/GET_AUTH', {
        resource: '/token/',
        payload: payload
      })
      .catch((error) => {
        this.error = error.response.data.detail
      })
      .finally(() => {
        this.signInLoader = false
      })
    }
  },
  validations: {
    user: {
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(64)
      }
    }
  }
}
</script>